<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ProfileNavigation />
                </b-col>
                    <b-col cols="12" md="8" lg="9">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">My subscription</h2>
                            <div v-if="!getUserProfilePending" class="profile__content mb-3 mt-1">
                                <Spinner size="medium" line-fg-color="#e91e63" />
                            </div>
                            <div v-else>
                                <div class="profile__content mb-3 mt-1">
                                    <b-row>
                                        <b-col cols="6">
                                            <p v-if="!getUserProfile[0].subscription && getUserProfilePending" class="no-results">No subscription</p>
                                        </b-col>
                                        <b-col cols="6">
                                            <router-link v-if="!getUserProfile[0].subscription && getUserProfilePending"  to="/sponsorship" class="details-button details-button--red float-right mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Upgrade now</router-link>
                                            <router-link v-if="(getUserProfile[0].subscription == 3 || getUserProfile[0].subscription == 4) && getUserProfilePending"  to="/sponsorship" class="details-button details-button--red float-right mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Resume</router-link>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="profile__content">
                                    <b-row>
                                        <b-col cols="12" md="4">
                                            <div class="profile__element">
                                                <p class="profile__label">Subscription status</p>
                                                <p v-if="!getUserProfile[0].subscription" class="profile__status profile__status--neutral">None</p>
                                                <p v-if="getUserProfile[0].subscription == 1" class="profile__status profile__status--positive">Active</p>
                                                <p v-else-if="getUserProfile[0].subscription == 2" class="profile__status profile__status--pending">Pending</p>
                                                <p v-else-if="getUserProfile[0].subscription == 3" class="profile__status profile__status--negative">Inactive</p>
                                                <p v-else-if="getUserProfile[0].subscription == 4" class="profile__status profile__status--cancelled">Cancelled</p>
                                            </div>
                                        </b-col>
                                        <b-col v-if="(getUserProfile[0].subscription == 1 || getUserProfile[0].subscription == 4) && getUserProfile[0].type" cols="12" md="4">
                                            <div class="profile__element">
                                                <p class="profile__label">Subscription type</p>
                                                <p v-if="getUserProfile[0].type == 1" class="profile__value">None</p>
                                                <p v-else-if="getUserProfile[0].type == 2" class="profile__value">Standard</p>
                                                <p v-else-if="getUserProfile[0].type == 3" class="profile__value">Premium</p>
                                                <p v-else-if="getUserProfile[0].type == 4" class="profile__value">Ultimate</p>
                                            </div>
                                        </b-col>
                                        <b-col v-if="(getUserProfile[0].subscription == 1 || getUserProfile[0].subscription == 4) && getUserProfile[0].valid" cols="12" md="4">
                                            <div class="profile__element">
                                                <p class="profile__label">Valid until</p>
                                                <p v-line-clamp:24="1" class="profile__value single-line">{{ getUserProfile[0].valid }}</p>
                                            </div>
                                        </b-col>
                                        <b-col v-if="getUserProfile[0].subscription == 1 && getUserProfile[0].next" cols="12" md="4">
                                            <div class="profile__element">
                                                <p class="profile__label">Next payment</p>
                                                <p v-line-clamp:24="1" class="profile__value single-line">{{ getUserProfile[0].next }}</p>
                                            </div>
                                        </b-col>
                                        <b-col v-if="getUserProfile[0].subscription == 1 && getUserProfile[0].cancel" cols="12" md="4">
                                            <div class="profile__element">
                                                <p class="profile__label">Cancel subscription</p>
                                                <button @click.prevent="showCancelAlert(getUserProfile[0].cancel)" class="interaction-button interaction-button--red ml-0"><simple-line-icons icon="close" size="small" color="#fff" />Cancel</button>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import SimpleLineIcons from 'vue-simple-line'
import Spinner from 'vue-simple-spinner'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {
    ProfileNavigation,
    SimpleLineIcons,
    Spinner
  },
  data: function () {
    return {

    }
  },
  methods: {
    showCancelAlert (sub) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to cancel your subscription?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#f1352e',
        cancelButtonColor: '#13b81b',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('cancelSubscription', { cancel: sub })
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getUserProfilePending'
    ])
  },
  beforeCreate () {
    this.$store.dispatch('getUserProfile')
  }
}
</script>
